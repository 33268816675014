import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'decodeHtmlString', standalone: true })
export class DecodeHtmlStringPipe implements PipeTransform {
  public transform(value: string) {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = value;
    return tempElement.innerText;
  }
}
